import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import diamond from "../assets/svg/diamond.svg";
import PriceCard from "../components/Membership/PriceCard";
import { fetchClient } from "../axios-config";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "../components/Membership/Modal";
import { notifyError } from "../utils/notifyToast";
import moment from "moment/moment";
import { notifyInfo } from "../utils/notifyToast";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import useFacebookPixel from "../hooks/useFacebookPixel";
import { PROFILE_DATA } from "../redux/action";

const Membership = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [memberShipList, setMemberShipList] = useState([]);
  const [selectedMemberShip, setSelectedMemberShip] = useState();
  const [memberShipData, setMemberShipData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [memberShipLoading, setMemberShipLoading] = useState(false);
  const [joinMemberShip, setJoinMembership] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [step, setStep] = useState(0);
  const [amount, setAmount] = useState();
  const [resetCoupon, setResetCoupon] = useState(false);
  const [profileData, setProfileData] = useState();
  const [data, setData] = useState({
    name: "",
    last_name: "",
    email: "",
    mobile: "",
    document_type: "emirates_id",
    document_id: "",
    expiry_date: "",
    document: "",
    student_check: false,
    student_id: "",
    student_type: "student_id",
    student_expiry_date: "",
  });
  const { heading, description, image } = useSelector(
    (state) => state.data.membership
  );
  const locale = useStorage();
  const dispatch = useDispatch();
  const { trackPageView } = useFacebookPixel();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  localStorage.setItem("last_visited_url", window.location.pathname);

  useEffect(() => {
    const getMemberShipList = async () => {
      setMemberShipLoading(true);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "?action=memberShipList",
        "",
        false
      );
      if (data) setMemberShipList(data.data);
      setMemberShipLoading(false);
    };
    getMemberShipList();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const getProfileDetails = async () => {
        const profileFormData = new FormData();
        profileFormData.append("action", "profileDetails");
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          "",
          profileFormData,
          false
        );
        if (data) {
          const profile = data.data;
          setProfileData(profile);
          setData(profile);
          dispatch({
            type: PROFILE_DATA,
            payload: profile,
          });
        }
      };
      const getMemberShipDetails = async () => {
        const memberShipFormData = new FormData();
        memberShipFormData.append("action", "membershipDetails");
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          "",
          memberShipFormData,
          false
        );
        if (data) setMemberShipData(data.data);
      };
      getProfileDetails();
      getMemberShipDetails();
    }
  }, [isAuthenticated]);

  const handleSubmitData = async (e) => {
    e.preventDefault();
    setStep(1);
  };

  const handlePay = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "profileUpdate");
    formData.append("name", data.name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("mobile", data.mobile);
    const resData = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (resData) {
      dispatch({
        type: PROFILE_DATA,
        payload: resData.data,
      });
      handleFileUploadData();
    } else isHandleError();
  };

  const handleFileUploadData = async () => {
    const uploadData = async () => {
      const fileFormData = new FormData();
      fileFormData.append("action", "documentProofUploader");
      fileFormData.append("document_type", data.document_type || "emirates_id");
      fileFormData.append("document_id", data.document_id);
      fileFormData.append(
        "expiry_date",
        moment(data.student_expiry_date).format("YYYY-MM-DD")
      );

      if (data.student_check) {
        fileFormData.append("student_id", data.student_id);
      }
      const resData = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        fileFormData,
        false
      );
      if (resData) {
        if (!data.student_check) {
          setJoinMembership(true);
          createPurchase();
        }
      } else isHandleError();
    };
    const uploadStudentData = async () => {
      const formData = new FormData();
      formData.append("action", "documentProofUploader");
      formData.append("document_type", data.student_type);
      formData.append("document_id", data.student_id);
      formData.append(
        "expiry_date",
        moment(data.student_expiry_date).format("YYYY-MM-DD")
      );

      const resData = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        formData,
        false
      );
      if (resData) {
        setJoinMembership(true);
        createPurchase();
      } else isHandleError();
    };
    uploadData();
    if (data.student_check) uploadStudentData();
  };

  const createPurchase = async () => {
    const items = {
      membership: {
        plan_id: selectedMemberShip.id,
      },
      coupon: coupon,
    };
    if (data.student_check) {
      items.membership.is_student = 1;
    }
    const formData = new FormData();
    formData.append("action", "createPurchase");
    formData.append("items", JSON.stringify(items));
    formData.append("site", "houseofwisdom.ae");
    formData.append("channel", "houseofwisdom-web");
    const resData = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (resData) initiatePayment(resData);
    else isHandleError();
  };

  const validateCoupon = async () => {
    if (!coupon) {
      notifyInfo(language.no_coupon[locale]);
      return;
    }
    const items = {
      membership: {
        plan_id: selectedMemberShip.id,
      },
      coupon: coupon,
    };
    if (data.student_check) {
      items.membership.is_student = 1;
    }
    const formData = new FormData();
    formData.append("action", "validateCoupon");
    formData.append("items", JSON.stringify(items));
    formData.append("site", "houseofwisdom.ae");
    formData.append("channel", "houseofwisdom-web");
    const resData = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (resData) {
      setAmount(resData.data.membership.price);
      setResetCoupon(true);
    }
    setLoading(false);
  };

  const initiatePayment = async (value) => {
    const formData = new FormData();
    formData.append("action", "initiatePayment");
    formData.append("purchase_number", value.purchase_number);
    formData.append(
      "redirect_to",
      `${window.location.origin}/profile?purchases`
    );
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) window.location.href = data.payment_link;
    else isHandleError();
  };

  const handlePriceCalc = (memberShip) => {
    var regularPrice = Number(memberShip.regular_price);
    var studentPrice = Number(memberShip.student_price);
    if (memberShip.temp_discount_percentage) {
      regularPrice =
        regularPrice / (1 - memberShip.temp_discount_percentage / 100);
      studentPrice =
        studentPrice / (1 - memberShip.temp_discount_percentage / 100);
    }
    regularPrice = Math.round(regularPrice);
    studentPrice = Math.round(studentPrice);


    
    const role = memberShip?.role?.toLowerCase().trim() || "";

 

    // Check if the role contains "standard" or "premium" (case-insensitive)
    const isStandardOrPremium = /standard|premium/i.test(role);

// Determine the label
// const studentLabel = isStandardOrPremium ? language.for_students_and_employees[locale] : language.for_students[locale];

const studentLabel = isStandardOrPremium ? language.for_students[locale] : language.for_students[locale];


    return `AED ${regularPrice} ${language.per_year[locale]} / AED ${studentPrice} ${studentLabel}`;

    
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setSelectedMemberShip();
      setLoading(false);
      setJoinMembership(false);
      setAlertText("");
      setData({
        name: "",
        last_name: "",
        email: "",
        mobile: "",
        document_type: "emirates_id",
        document_id: "",
        expiry_date: "",
        document: "",
        student_check: false,
        student_id: "",
        student_type: "student_id",
        student_expiry_date: "",
      });
      setCoupon("");
      setAmount();
      setStep(0);
      setResetCoupon(false);
    }, 600);
  };

  const isHandleError = (err = language.something_went_wrong[locale]) => {
    setLoading(false);
    notifyError(err);
  };

  return (
    <>
      <Banner
        url={process.env.REACT_APP_CMS + image.data.attributes.url}
        title={heading}
        desc={description}
      />
      <div className="mt-[60px] md:mt-[84px] w-full flex justify-center">
        <div className="w-11/12 max-w-[1800px] flex flex-col md:flex-row gap-[12px] justify-evenly">
          {memberShipLoading ? (
            <>
              {["", ""].map((loader, id) => (
                <div
                  key={id}
                  className="w-full bg-primaryBg p-[28px] flex flex-col justify-between rounded-[8px]"
                >
                  <div role="status" class="max-w-sm animate-pulse">
                    <img
                      src={diamond}
                      alt=""
                      className="h-[25px] md:h-[40px]"
                    />
                    <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 mt-4"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5 mt-4"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {memberShipList
                .sort((a, b) => a.level - b.level)
                .map(
                  (memberShip, idx) =>
                    (window.location.pathname === "/membership"
                      ? memberShip.plans[0].hide === "0"
                      : true) && (
                      <PriceCard
                        idx={idx}
                        title={memberShip.label}
                        discountedPrice={handlePriceCalc(memberShip.plans[0])}
                        
                        
                        price={(() => {

                    
                          const plan = memberShip?.plans?.[0];
                          if (!plan) {
                            return `${language.for_students[locale]}`;
                          }
                          
                          const role = memberShip?.role?.toLowerCase().trim() || "";
                          const isStandardOrPremium = /standard|premium/i.test(role);
                          
                          const studentLabel = isStandardOrPremium
                            ? language.for_students_and_employees[locale]
                            : language.for_students[locale];
                          
                          return `${plan.regular_price} AED ${language.per_year[locale] || "per year"} / ${plan.student_price} AED ${studentLabel}`;
                       
                       

                        })()}
                        
                        
                        
                        // price={`${memberShip.plans[0].regular_price} AED ${language.per_year[locale]} / ${memberShip.plans[0].student_price} AED ${language.for_students[locale]}`}
                        access={memberShip.access_list_info}
                        discount={memberShip.discount_list_info}
                        memberShipData={memberShipData}
                        memberShipValue={memberShipList.find(
                          (member) => member.id === memberShipData?.role_id
                        )}
                        level={memberShip.level}
                        selectedMemberShip={
                          memberShipData?.role_id === memberShip.id
                        }
                        setIsModalOpen={() => {
                          setJoinMembership(false);
                          setAlertText("");
                          setIsModalOpen(true);
                          setSelectedMemberShip(memberShip);
                          setData(profileData);
                        }}
                        offerTag={memberShip.plans[0].offer_tag}
                        discountPercent={
                          memberShip.plans[0].temp_discount_percentage
                        }
                      />
                    )
                )}
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        closeModal={handleModalClose}
        data={data}
        setData={setData}
        loading={loading}
        joinMemberShip={joinMemberShip}
        validateCoupon={validateCoupon}
        handleSubmitData={handleSubmitData}
        handlePay={handlePay}
        profileData={profileData}
        selectedMemberShip={selectedMemberShip}
        alertText={alertText}
        coupon={coupon}
        setCoupon={setCoupon}
        resetCoupon={resetCoupon}
        setResetCoupon={setResetCoupon}
        step={step}
        setStep={setStep}
        amount={amount}
        setAmount={setAmount}
      />
    </>
  );
};

export default Membership;