import React, { useState } from "react";
import moment from "moment";
import language from "../../Json/locale.json";
import close from "../../assets/svg/close.svg";
import tick from "../../assets/svg/tick.svg";
import useStorage from "../../hooks/useStorage";
import { notifyInfo } from "../../utils/notifyToast";

const Summary = ({
  loading,
  selectedTimeBlock,
  selectedPod,
  selectedDate,
  count,
  amount,
  setAmount,
  notes,
  setNotes,
  coupon,
  setCoupon,
  validateCoupon,
  resetCoupon,
  setResetCoupon,
}) => {
  const locale = useStorage();
  const reg = /[&<>"'/\\]/gi;
  const regex = /^[A-Za-z\u0621-\u064A\s]*$/;
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
    "\\": "&#92;",
  };

  const handleNotes = (e) => {
    const { value } = e.target;
    if (!regex.test(value)) {
      notifyInfo(language.valid_bookingPurpose[locale]);
      return;
    }
    const sanitizedValue = value.replace(reg, (match) => map[match]);
    setNotes({ sanitizedValue, value });
  };

  const handleClearCoupon = () => {
    setAmount(0);
    setCoupon("");
    setResetCoupon(false);
  };

  return (
    <div className="flex flex-col justify-between mt-[84px] md:mt-0">
      <div className="bg-transparent md:min-w-[400px] w-full flex justify-between p-[10px] md:p-[20px] rounded-[13px] font-SansSerif">
        <div>
          <h3 className="text-white text-[17px] font-semibold">
            {selectedPod?.name}
          </h3>
          <h3 className="text-white text-[17px]">
 
            {selectedPod.space_type?.toLowerCase().includes("coworking") 
                    ? (
                      <>
                        {language.capacity[locale]}: {selectedPod?.total_chairs} {language.chairs[locale]} <br />
                        {language.available[locale]}: {selectedPod?.available_chairs} {language.chairs[locale]}
                      </>
                    ) : (
                      <>
                        {language.capacity[locale]}: {selectedPod?.capacity} {language.people[locale]}
                      </>
                    )
                  }




          </h3>
          <br />
          <h3 className="text-white text-[17px]">
            {
              language[
                (
                  amount
                    ? amount.total !== amount.sub_total
                    : selectedPod?.total_cost !== selectedPod?.sub_total
                )
                  ? "regular_price"
                  : "price"
              ][locale]
            }
            : AED {(selectedPod?.sub_total * 0.95).toFixed(2)}
          </h3>
          {(amount
            ? amount.total !== amount.sub_total
            : selectedPod?.total_cost !== selectedPod?.sub_total) && (
            <h3 className="text-white text-[17px]">
              {language.discount_price[locale]}: AED{" "}
              {(
                (amount ? amount.total : selectedPod?.total_cost) * 0.95
              ).toFixed(2)}
            </h3>
          )}
          <h3 className="text-white text-[17px]">
            {language.includes_VAT[locale]} (5%): AED{" "}
            {((amount ? amount.total : selectedPod?.total_cost) * 0.05).toFixed(
              2
            )}
          </h3>
          <h3 className="text-white text-[17px]">
            {language.total[locale]}: AED{" "}
            {(amount ? amount.total : selectedPod?.total_cost).toFixed(2)}
            {/* {!!amount ? (
              <>
                <s>AED {selectedPod?.total_cost}</s> <>AED {amount}</>
              </>
            ) : (
              <>AED {selectedPod?.total_cost}</>
            )} */}
          </h3>
          <br />
          <h3 className="text-white text-[17px]">
            {moment(selectedDate).format("LL")}
          </h3>
          <h3 className="text-white text-[17px]">
            {language.from[locale]} {selectedTimeBlock.startTime}{" "}
            {language.to[locale]} {selectedTimeBlock.endTime}
          </h3>
          <h3 className="text-white text-[17px]">
            {language.for[locale]} {count} {language.no_of_people[locale]}
          </h3>
        </div>
        {/* <p className="text-white text-[17px] font-semibold flex flex-col">
          {selectedPod?.total_cost !== selectedPod?.sub_total && (
            <s>AED {selectedPod?.sub_total}</s>
          )}
          {!!amount ? (
            <>
              <s>AED {selectedPod?.total_cost}</s>
              <>AED {amount}</>
            </>
          ) : (
            <>AED {selectedPod?.total_cost}</>
          )}{" "}
          {selectedPod && (
            <p className="text-[15px] font-normal">
              <i>
                ({language.includes_VAT[locale]}{" "}
                {selectedPod?.total_cost !== selectedPod?.sub_total
                  ? selectedPod?.sub_total * 0.05
                  : !!amount
                  ? amount * 0.05
                  : selectedPod?.total_cost * 0.05}{" "}
                AED)
              </i>
            </p>
          )}
        </p> */}
      </div>
      <div className="relative flex items-center">
        <input
          type="text"
          className="border-none outline-none text-white placeholder-white w-full bg-[#ffffff4d] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
          placeholder={language.coupon[locale]}
          name="coupon"
          autoComplete="off" 
          required
          value={coupon}
          onChange={(e) => {
            setCoupon(e.target.value);
            if (!e.target.value) {
              setResetCoupon(false);
            }
          }}
        />
        <button
          disabled={loading}
          onClick={resetCoupon ? handleClearCoupon : validateCoupon}
          className={`absolute ${
            locale === "en" ? "right-4" : "right-[19rem] md:right-[22rem]"
          } top-1/2 transform -translate-y-1/2 h-[36px] w-[36px] rounded-full text-white gradient-border`}
        >
          <img
            src={resetCoupon ? close : tick}
            alt={resetCoupon ? "close" : "tick"}
            className={`absolute top-1/2 left-1/2 transform ${
              !resetCoupon && "h-5"
            } -translate-x-1/2 -translate-y-1/2`}
          />
        </button>
        {/* <input
          type="text"
          className="border-none outline-none text-white placeholder-white w-full bg-[#ffffff4d] rounded-[30px] px-[28px] py-[16px] md:flex items-center"
          placeholder={language.coupon[locale]}
          name="coupon"
          required
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
        />
        <button
          disabled={loading}
          onClick={validateCoupon}
          className={`absolute ${
            locale === "en"
              ? "right-20 md:right-24"
              : "right-[16rem] md:right-[19rem]"
          } top-1/2 transform -translate-y-1/2 px-3 py-1 rounded-xl text-white gradient-border`}
        >
          Submit
        </button>
        <button
          disabled={loading}
          onClick={() => {
            setAmount(0);
            setCoupon("");
          }}
          className={`absolute ${
            locale === "en"
              ? "right-2 md:right-6"
              : "right-[11.5rem] md:right-[14.5rem]"
          } top-1/2 transform -translate-y-1/2 px-3 py-1 rounded-xl text-white gradient-border`}
        >
          reset
        </button> */}
      </div>
      <div className="px-[24px] py-[16px] mt-[1rem] bg-[#ffffff4d] rounded-[15px] px-[15px] py-[8px] flex items-center">
        <textarea
          placeholder={language.booking_purpose[locale]}
          className="text-[16px] placeholder-white text-white bg-transparent border-none outline-none w-full"
          type="text"
          rows={3}
          value={notes.value}
          onChange={handleNotes}
        />
      </div>
    </div>
  );
};

export default Summary;
